import { createColumnHelper } from '@tanstack/react-table'
import i18next from 'i18next'

import type { TreatmentsType } from 'types/treatments'

import StatusTag from 'components/Treatments/StatusTag'
import { defaultColumnOptions } from 'utils/table'
import { getName } from 'utils/text'

const columnHelper = createColumnHelper<TreatmentsType>()
export const treatmentsColumns = [
  columnHelper.accessor(row => getName(row, 'patient'), {
    ...defaultColumnOptions<TreatmentsType>(),
    header: 'Patient name',
    id: 'patientName',
    cell: info => {
      return getName(info.row.original, 'patient')
    },
  }),
  columnHelper.accessor('patientAge', {
    ...defaultColumnOptions<TreatmentsType>(),
    enableColumnFilter: false,
    enableSorting: true,
    header: 'Age',
  }),
  columnHelper.accessor('week', {
    ...defaultColumnOptions<TreatmentsType>(),
    header: 'Week',
    enableColumnFilter: false,
    enableSorting: true,
    meta: { width: '20%' },
    cell: info => {
      const { week, year } = info.row.original
      return `${year} - ${i18next.t('Week')} ${week}`
    },
  }),
  columnHelper.accessor('proceduresCount', {
    ...defaultColumnOptions<TreatmentsType>(),
    meta: { width: '20%' },
    enableColumnFilter: false,
    enableSorting: true,
    header: 'Procedures count',
  }),
  columnHelper.accessor('visit', {
    ...defaultColumnOptions<TreatmentsType>(),
    meta: { width: '10%' },
    enableColumnFilter: false,
    header: 'Visit',
    cell: ({ row: { original } }) => {
      return original.visit ?? '--'
    },
  }),
  columnHelper.accessor('planInDays', {
    ...defaultColumnOptions<TreatmentsType>(),
    meta: { width: '10%' },
    enableColumnFilter: false,
    header: 'Plan in days',
    cell: ({ row: { original } }) => {
      return original.planInDays ?? '--'
    },
  }),
  columnHelper.accessor('hasPriority', {
    ...defaultColumnOptions<TreatmentsType>(),
    header: 'Priority',
    enableColumnFilter: false,
    enableSorting: true,
    cell: ({ row: { original } }) => {
      return original.hasPriority ? i18next.t('Yes') : i18next.t('No')
    },
  }),
  columnHelper.accessor('status', {
    ...defaultColumnOptions<TreatmentsType>(),
    header: 'Status',
    enableColumnFilter: false,
    enableSorting: true,
    meta: { width: '15%' },
    cell: ({ row: { original } }) => {
      return <StatusTag status={original.status} />
    },
  }),
]
