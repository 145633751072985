import {
  Button,
  DatePicker,
  Flex,
  Label,
  Text,
  alert,
} from '@weareredlight/design-system'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import type { CustomEventType } from 'components/BigCalendar'
import type {
  AppointmentOnCalendarType,
  AppointmentType,
} from 'types/appointments'
import type { ProcedureType } from 'types/procedures'
import type { ProviderScheduleType } from 'types/providers'
import type { TreatmentType } from 'types/treatments'

import AppointmentDetails from 'components/Appointments/AppointmentDetails'
import BigCalendar from 'components/BigCalendar'
import useDateParams from 'hooks/useDateParams'
import { GenericPath } from 'router/enums'
import { useCurrentUser } from 'userContext'
import { getName } from 'utils/text'
import { TIME_FORMAT, addDuration, format, limitEndDate } from 'utils/time'

dayjs.extend(isBetween)

type AppointmentsCalendarProps = {
  treatment: TreatmentType | null
  activeProcedure?: { id: string; procedure: ProcedureType }
  activeAppointment?: AppointmentType
  customDate: string
  setCustomDate: (date: string) => void
  setDraggedEvent: (event: AppointmentOnCalendarType) => void
  eventsToShow: CustomEventType[]
  isCancelledTreatment: boolean
  selectedProviderSchedules: ProviderScheduleType[]
}

const AppointmentsCalendar = ({
  treatment,
  activeProcedure,
  activeAppointment,
  customDate,
  setCustomDate,
  setDraggedEvent,
  eventsToShow,
  isCancelledTreatment,
  selectedProviderSchedules,
}: AppointmentsCalendarProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { isAdmin } = useCurrentUser()

  const requiresCellBank = useMemo(
    () => Boolean(activeProcedure?.procedure.isCellBank),
    [activeProcedure],
  )

  const workingHours = useMemo(() => {
    const start = requiresCellBank
      ? treatment?.organization.cellBankWorkingHours.startTime || '00:00:00'
      : treatment?.organization.workingHours.startTime || '00:00:00'

    const end = requiresCellBank
      ? treatment?.organization.cellBankWorkingHours.endTime || '00:00:00'
      : treatment?.organization.workingHours.endTime || '00:00:00'

    return { start, end }
  }, [requiresCellBank, treatment?.organization])

  const {
    firstDayOfWeek,
    lastDayOfWeek,
    currentYear,
    currentWeek,
    isCurrentWeek,
  } = useDateParams(customDate)

  const defaultTime = useMemo(() => {
    const startingHour = Number(workingHours.start.slice(0, 2))
    return dayjs(customDate).hour(startingHour)
  }, [customDate, workingHours.start])

  const eventComponent = useMemo(
    () => ({
      event: (event: { event: CustomEventType; title: string }) => {
        const appointment = event?.event?.appointment
        const curTreatment = appointment?.treatmentProcedure.treatment
        const isDifTreatment = curTreatment?.id !== treatment?.id

        return (
          <div className="event-details">
            <Flex direction="column" align="start">
              <Text variant="microCopy" color="white">
                {event?.title}
              </Text>
              {appointment && isDifTreatment && (
                <Text variant="microCopy" color="neutral700">
                  {getName(curTreatment, 'patient')}
                </Text>
              )}
            </Flex>
            {!!appointment && (
              <div className="event-trigger">
                <AppointmentDetails
                  appointment={appointment}
                  isCancelledTreatment={isCancelledTreatment}
                />
              </div>
            )}
          </div>
        )
      },
    }),
    [isCancelledTreatment, treatment?.id],
  )

  const getEventType = useCallback(
    ({ id, appointment, procedure }: CustomEventType) => {
      const eventStyle =
        appointment?.treatmentProcedure?.procedure?.isCellBank ||
        procedure?.isCellBank
          ? 'cell-bank-event'
          : ''
      if (!activeProcedure) return { className: eventStyle }

      if (id === activeProcedure.id)
        return { className: `${eventStyle} current-appointment` }

      const isSameTreatment =
        appointment?.treatmentProcedure?.treatment?.id === treatment?.id

      return {
        className: isSameTreatment
          ? eventStyle
          : `${eventStyle} inactive-event`,
      }
    },
    [activeProcedure, treatment?.id],
  )

  const deactivateSlot = useCallback(
    (date: Date) => {
      const time = format(date, TIME_FORMAT)
      const isInactive = time < workingHours.start || time >= workingHours.end

      if (isInactive) return { className: 'inactive-slot' }

      if (!selectedProviderSchedules.length)
        return { className: 'inactive-slot' }

      const isOutsideSchedule = !selectedProviderSchedules.find(
        ({ startTime, endTime }) =>
          dayjs(date).isBetween(dayjs(startTime), dayjs(endTime), null, '[]') &&
          dayjs(date).isBefore(dayjs(endTime), 'hour'),
      )

      if (isOutsideSchedule) return { className: 'scheduled-slot' }

      return { className: '' }
    },
    [workingHours, selectedProviderSchedules],
  )

  // Create new events or reposicionate dragged events
  const handleEvent = (event: AppointmentOnCalendarType) => {
    if (activeProcedure) {
      const eventStart = format(event.start, TIME_FORMAT)
      const procedureDuration =
        activeProcedure?.procedure.durationData?.mainTime || ''
      const limitEnd = limitEndDate(
        workingHours.end,
        event.start,
        procedureDuration,
      )

      if (eventStart >= workingHours.start && eventStart <= limitEnd) {
        if (!activeAppointment) {
          setDraggedEvent({
            event: { ...activeProcedure, appointment: null },
            start: new Date(event.start.toISOString()),
            end: addDuration(event.start, procedureDuration),
          })
        } else {
          setDraggedEvent(event)
        }
      } else {
        alert.error(
          t('Invalid time'),
          t(
            `You can't book an appointment here as the ${
              requiresCellBank ? 'cell bank' : 'clinic'
            } is closed at this time.`,
          ),
        )
      }
    }
  }

  return (
    <Flex direction="column" gap="xlg" align="end">
      <Flex gap="xxsm">
        <Text variant="h3">{`${firstDayOfWeek + ' - ' + lastDayOfWeek}`}</Text>
        <Text variant="paragraph" color="accent">{`${currentYear}, ${t(
          'week',
        )} ${currentWeek}`}</Text>
        {activeProcedure && !activeAppointment && (
          <Flex gap="xxxsm">
            <Label label={`${t('Jump to')}:`} />
            <Button
              variant="tertiary"
              disabled={isCurrentWeek}
              onClick={() => setCustomDate(dayjs().toJSON())}
            >
              {t('This week')}
            </Button>
            <DatePicker
              name="date"
              value={customDate}
              onChange={date => setCustomDate(String(date))}
              isWeekSelector={true}
            />
          </Flex>
        )}
      </Flex>
      <BigCalendar
        events={eventsToShow}
        date={customDate}
        scrollToTime={defaultTime}
        selectable={activeProcedure && !activeAppointment}
        onSelecting={() => false}
        onNavigate={() => false}
        onView={() => false}
        onSelectEvent={({ id }: CustomEventType) => {
          if (!isCancelledTreatment && isAdmin && !activeProcedure) {
            navigate(`${id}/${GenericPath.EDIT}`)
          }
        }}
        onSelectSlot={handleEvent}
        onEventDrop={handleEvent}
        draggableAccessor={({ id }: CustomEventType) =>
          activeProcedure && id === activeProcedure.id
        }
        resizeAccessor={() => false}
        views={{ work_week: true }}
        toolbar={false}
        style={{ height: 560 }}
        eventPropGetter={getEventType}
        slotPropGetter={deactivateSlot}
        components={eventComponent}
        showMultiDayTimes={false}
      />
    </Flex>
  )
}

export default AppointmentsCalendar
