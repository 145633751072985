import { alert, Button, Dialog, Text } from '@weareredlight/design-system'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import type { AppointmentInputType } from 'types/appointments'

import AppointmentForm from './AppointmentForm'

import api from 'api/api'
import { ApptFields } from 'components/Appointments/utils'
import EntityDetail from 'components/EntityDetail'
import { useFormManager } from 'hooks/useFormManager'
import { useSchedule } from 'pages/Schedule/ScheduleAppointment'
import { AppointmentInputSchema } from 'schemas/appointment'
import { getName } from 'utils/text'

const AppointmentEdit = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const {
    draggedEvent,
    activeAppointment,
    reloadAppointmentsList,
    resetActiveProcedure,
    resources,
    isLoadingResources,
    updateTreatment,
    setResourcesSelected,
  } = useSchedule()

  const [dialogOpen, setDialogOpen] = useState(false)

  const handleRemoveConfirm = async () => {
    if (!activeAppointment) return
    setDialogOpen(false)

    try {
      await api.cancelAppointment(activeAppointment.id)
      reloadAppointmentsList()
      navigate('', { replace: true })
      alert.success(
        `${t('Success')}!`,
        `${t('Appointment cancelled successfully.')}`,
        {
          position: 'bottom-right',
        },
      )
    } catch (error) {
      alert.error(`${t('Error')}!`, `${t('Failed to cancel the appointment.')}`)
    }
  }

  const form = useFormManager<AppointmentInputType>({
    defaultValues: {
      [ApptFields.START_TIME]: activeAppointment?.startTime,
      [ApptFields.END_TIME]: activeAppointment?.endTime,
      [ApptFields.DURATION_BEFORE]: activeAppointment?.durationBefore,
      [ApptFields.TREATMENT_PROCEDURE_ID]:
        activeAppointment?.treatmentProcedure?.id,
      [ApptFields.ROOM_ID]: activeAppointment?.room?.id,
      [ApptFields.PROVIDER_IDS]: activeAppointment?.providers?.map(
        ({ id }) => id,
      ),
    },
    enableReinitialize: true,
    schema: AppointmentInputSchema,
    onCancel: () => {
      resetActiveProcedure()
      navigate('', { replace: true })
    },
    onSubmit: async (input: AppointmentInputType) => {
      if (!activeAppointment) return
      await api.updateAppointment({ id: activeAppointment.id, input })
      resetActiveProcedure()
      navigate('', { replace: true })
      updateTreatment()
      alert.success(
        `${t('Success')}!`,
        `${t('Appointment edited successfully.')}`,
        {
          position: 'bottom-right',
        },
      )
    },
  })

  const removeButton = activeAppointment?.id ? (
    <Button
      variant="danger"
      disabled={form.formState.isSubmitting}
      onClick={() => setDialogOpen(true)}
    >
      {t('Cancel Appointment')}
    </Button>
  ) : (
    <></>
  )

  if (!activeAppointment) return null

  return (
    <>
      <Text variant="h2">
        {t('Edit appointment')}
        <Text color="neutral800">
          {' '}
          - {t('for')}{' '}
          <b>{activeAppointment.treatmentProcedure.procedure.name}</b>
        </Text>
      </Text>
      <EntityDetail label={t('Patient')}>
        {getName(activeAppointment.treatmentProcedure.treatment, 'patient')}
      </EntityDetail>
      <AppointmentForm
        {...form}
        procedure={activeAppointment.treatmentProcedure.procedure}
        treatmentProcedure={activeAppointment.treatmentProcedure}
        event={draggedEvent}
        resources={resources}
        isLoading={isLoadingResources}
        setResourcesSelected={setResourcesSelected}
        additionalActions={removeButton}
      />
      <Dialog
        open={dialogOpen}
        onConfirm={handleRemoveConfirm}
        closeFn={() => setDialogOpen(false)}
        title={t('Are you sure you want to cancel this appointment?')}
        description={t('This action cannot be undone.')}
        variant="danger"
        confirmButtonText={t('Confirm')}
        cancelButtonText={t('Cancel')}
      />
    </>
  )
}

export default AppointmentEdit
