import { createColumnHelper } from '@tanstack/react-table'
import dayjs from 'dayjs'

import type { AppointmentType } from 'types/appointments'

import { defaultColumnOptions } from 'utils/table'
import { getName } from 'utils/text'
import {
  format,
  APPOINTMENT_FORMAT,
  timeStringToHoursAndMinutes,
  addDuration,
} from 'utils/time'

const columnHelper = createColumnHelper<AppointmentType>()
export const appointmentsColumns = [
  columnHelper.accessor(
    row => getName(row.treatmentProcedure.treatment, 'patient'),
    {
      ...defaultColumnOptions<AppointmentType>(),
      enableColumnFilter: true,
      enableSorting: true,
      header: 'Patient name',
      id: 'patientName',
      cell: info => {
        return getName(
          info.row.original.treatmentProcedure.treatment,
          'patient',
        )
      },
    },
  ),
  columnHelper.accessor(row => `${row.treatmentProcedure.procedure.name}`, {
    ...defaultColumnOptions<AppointmentType>(),
    enableColumnFilter: false,
    enableSorting: false,
    header: 'Procedure',
    id: 'procedure',
    cell: info => {
      return info.row.original.treatmentProcedure.procedure.name
    },
  }),
  columnHelper.accessor('startTime', {
    ...defaultColumnOptions<AppointmentType>(),
    meta: { width: '15%' },
    enableColumnFilter: false,
    enableSorting: true,
    header: 'Starting at',
    cell: ({ row: { original } }) => {
      return original.startTime
        ? format(original.startTime, APPOINTMENT_FORMAT)
        : '--'
    },
  }),
  columnHelper.accessor(() => 'therapyAt', {
    ...defaultColumnOptions<AppointmentType>(),
    meta: { width: '15%' },
    enableColumnFilter: false,
    enableSorting: false,
    header: 'Therapy At',
    cell: ({ row: { original } }) => {
      let therapyAt = null

      if (
        original.startTime &&
        original.treatmentProcedure.procedure.prePreparation
      ) {
        therapyAt = addDuration(
          dayjs(original.startTime).toDate(),
          original.treatmentProcedure.procedure.prePreparation,
        )
      }
      return therapyAt ? format(therapyAt, APPOINTMENT_FORMAT) : '--'
    },
  }),
  columnHelper.accessor('endTime', {
    ...defaultColumnOptions<AppointmentType>(),
    meta: { width: '15%' },
    enableColumnFilter: false,
    enableSorting: true,
    header: 'Ending at',
    cell: ({ row: { original } }) => {
      return original.endTime
        ? format(original.endTime, APPOINTMENT_FORMAT)
        : '--'
    },
  }),
  columnHelper.accessor('durationBefore', {
    ...defaultColumnOptions<AppointmentType>(),
    meta: { width: '10%' },
    enableColumnFilter: false,
    enableSorting: false,
    header: 'Duration',
    cell: ({ row: { original } }) => {
      return original.durationBefore
        ? timeStringToHoursAndMinutes(
            original.treatmentProcedure.procedure.durationData.mainTime,
          )
        : '--'
    },
  }),
]
