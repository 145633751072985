import { Flex } from '@weareredlight/design-system'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import unionBy from 'lodash/unionBy'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useParams,
  Outlet,
  useOutletContext,
  useNavigate,
} from 'react-router-dom'

import type { CustomEventType } from 'components/BigCalendar'
import type {
  AppointmentOnCalendarType,
  AppointmentType,
  AppointmentsParams,
} from 'types/appointments'
import type { ProcedureType } from 'types/procedures'
import type { ProviderScheduleType } from 'types/providers'
import type {
  ResourcesParamsType,
  ResourcesType,
  TreatmentType,
} from 'types/treatments'
import type { PaginatedRequest } from 'types/types'

import api from 'api/api'
import AppointmentsCalendar from 'components/Appointments/AppointmentsCalendar'
import Card from 'components/Card'
import PageTemplate from 'components/PageTemplate'
import useDateParams from 'hooks/useDateParams'
import { useRequest } from 'hooks/useRequest'
import { RootPath } from 'router/enums'
import { TreatmentStatuses } from 'utils/enums'

dayjs.extend(duration)

type ResourcesSelectedType = { room?: string; providers: string[] }

type ContextType = {
  draggedEvent: AppointmentOnCalendarType | null
  treatment: TreatmentType | null
  appointments: PaginatedRequest<AppointmentType>
  isLoading: boolean
  updateTreatment: () => void
  activeProcedure?: { id: string; procedure: ProcedureType }
  resetActiveProcedure: () => void
  reloadAppointmentsList: () => void
  activeAppointment?: AppointmentType
  resources: ResourcesType
  isLoadingResources: boolean
  setResourcesSelected: (key: ResourcesSelectedType) => void
  isCancelledTreatment: boolean
}

export const useSchedule = () => {
  return useOutletContext<ContextType>()
}

const ScheduleAppointment = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { id, treatmentProcedureId } = useParams<{
    id: string
    treatmentProcedureId: string
  }>()

  const [customDate, setCustomDate] = useState(dayjs().format('YYYY-MM-DD'))

  const { firstDayOfWeekDate, lastDayOfWeekDate } = useDateParams(customDate)

  const [draggedEvent, setDraggedEvent] =
    useState<AppointmentOnCalendarType | null>(null)

  const [resourcesSelected, setResourcesSelected] =
    useState<ResourcesSelectedType>({
      room: undefined,
      providers: [],
    })

  const {
    data: treatment,
    doRequest: getTreatment,
    isLoading: isLoadingTreatment,
  } = useRequest<TreatmentType, { id: string }>(api.getTreatment)

  const {
    data: appointments,
    doRequest: getAppointments,
    isLoading: isLoadingAppointments,
  } = useRequest<PaginatedRequest<AppointmentType>, AppointmentsParams>(
    api.getAppointments,
  )

  const {
    data: resources,
    doRequest: getResources,
    setData: setResources,
    isLoading: isLoadingResources,
  } = useRequest<ResourcesType, ResourcesParamsType>(api.getResources)

  const isLoading = useMemo(
    () => isLoadingAppointments || isLoadingTreatment,
    [isLoadingAppointments, isLoadingTreatment],
  )

  const activeProcedure = useMemo(
    () => treatment?.procedures.find(({ id }) => id === treatmentProcedureId),
    [treatmentProcedureId, treatment?.procedures],
  )

  const activeAppointment = useMemo(() => {
    if (!appointments?.data || !activeProcedure) return undefined
    return appointments.data.find(
      ({ treatmentProcedure }) => treatmentProcedure.id === activeProcedure.id,
    )
  }, [appointments?.data, activeProcedure])

  const defaultDate = useMemo(() => {
    if (activeAppointment?.startTime)
      return dayjs(activeAppointment.startTime).startOf('w').toISOString()
    if (!treatment || !treatment.year || !treatment.week)
      return dayjs().toISOString()
    const maxWeeks = dayjs().year(treatment.year).isoWeeksInYear()
    const validWeek = Math.min(treatment.week, maxWeeks)

    return dayjs()
      .year(treatment.year)
      .startOf('year')
      .add(validWeek - 1, 'week')
      .startOf('week')
      .toISOString()
  }, [treatment, activeAppointment])

  const patientAppointments = useMemo(() => {
    if (!appointments) return []

    const updatedEvents: CustomEventType[] = appointments?.data.map(appt => {
      const isActiveEvent = draggedEvent?.event?.appointment?.id === appt.id
      return {
        title: appt.treatmentProcedure?.procedure.name,
        start: isActiveEvent
          ? draggedEvent?.start
          : dayjs(appt.startTime).toDate(),
        end: isActiveEvent ? draggedEvent?.end : dayjs(appt.endTime).toDate(),
        id: appt.treatmentProcedure?.id,
        appointment: appt,
        procedure: appt.treatmentProcedure?.procedure,
      }
    })

    if (
      activeProcedure &&
      draggedEvent &&
      !draggedEvent?.event?.appointment?.id
    ) {
      updatedEvents.push({
        title: activeProcedure.procedure.name,
        start: draggedEvent?.start,
        end: draggedEvent?.end,
        procedure: activeProcedure.procedure,
        id: activeProcedure.id,
        appointment: null,
      })
    }

    return updatedEvents
  }, [activeProcedure, appointments, draggedEvent])

  const otherAppointments: CustomEventType[] = useMemo(() => {
    const providersAppts = resources?.providers.reduce((acc, cur) => {
      return !resourcesSelected.providers?.includes(cur.id) ||
        !cur?.bookedAppointments
        ? acc
        : [...acc, ...cur.bookedAppointments]
    }, [] as AppointmentType[])
    const roomsAppts = resources?.rooms.reduce((acc, cur) => {
      return resourcesSelected.room !== cur.id || !cur?.bookedAppointments
        ? acc
        : [...acc, ...cur.bookedAppointments]
    }, [] as AppointmentType[])

    const allBooked = unionBy(providersAppts, roomsAppts, 'id') || []

    const mappedAppointments = allBooked.map(appt => ({
      title: appt.treatmentProcedure.procedure.name,
      start: dayjs(appt.startTime).toDate(),
      end: dayjs(appt.endTime).toDate(),
      id: appt.treatmentProcedure?.id,
      appointment: appt,
      procedure: appt.treatmentProcedure?.procedure,
    }))
    return mappedAppointments
  }, [resources, resourcesSelected])

  const resetActiveProcedure = useCallback(() => {
    navigate(-1)
    setDraggedEvent(null)
  }, [navigate])

  const getAppointmentsCallback = useCallback(() => {
    if (id && treatment?.patient.id) {
      getAppointments({
        treatmentId: id,
        patientId: treatment?.patient.id,
      })
    }
  }, [getAppointments, id, treatment])

  const reloadAppointmentsList = useCallback(() => {
    getAppointmentsCallback()
    navigate(-1)
    setDraggedEvent(null)
  }, [navigate, getAppointmentsCallback])

  const updateTreatment = useCallback(() => {
    if (!id) return
    getTreatment({ id })
  }, [getTreatment, id])

  useEffect(() => {
    if (!customDate && defaultDate) {
      setCustomDate(defaultDate)
    }
  }, [customDate, defaultDate])

  useEffect(() => {
    if (activeAppointment) {
      const updatedDefaultDate = defaultDate
      if (customDate !== updatedDefaultDate) {
        setCustomDate(updatedDefaultDate)
      }
    }
  }, [activeAppointment, defaultDate, customDate])

  useEffect(() => {
    updateTreatment()
  }, [updateTreatment])

  useEffect(() => {
    getAppointmentsCallback()
  }, [getAppointmentsCallback])

  useEffect(() => {
    if (!treatment || !activeProcedure) {
      setResources(null)
    } else {
      const startDate = firstDayOfWeekDate
        ? dayjs(firstDayOfWeekDate).format('YYYY-MM-DDT00:00:00')
        : null
      const endDate = lastDayOfWeekDate
        ? dayjs(lastDayOfWeekDate).format('YYYY-MM-DDT00:00:00')
        : null

      getResources({
        organizationId: treatment.organization.id,
        procedureId: activeProcedure?.procedure.id,
        id: treatment.id,
        startDateTime: startDate,
        endDateTime: endDate,
      })
    }
  }, [
    activeProcedure,
    getResources,
    setResources,
    treatment,
    firstDayOfWeekDate,
    lastDayOfWeekDate,
  ])

  const isCancelledTreatment = useMemo(() => {
    return String(treatment?.status) === `${TreatmentStatuses.CANCELLED}`
  }, [treatment?.status])

  const selectedProviderSchedules = useMemo(
    () =>
      resources?.providers.reduce((acc, cur) => {
        return !resourcesSelected.providers?.includes(cur.id)
          ? acc
          : [...acc, ...cur.schedules]
      }, [] as ProviderScheduleType[]) || [],
    [resources?.providers, resourcesSelected.providers],
  )

  return (
    <PageTemplate
      title={t('Appointments Scheduling')}
      goBackTo={RootPath.SCHEDULE}
    >
      <Flex align="start" gap="xxlg" css={{ width: '100%' }}>
        <Flex direction="column" align="start" gap="lg" css={{ width: '40%' }}>
          <Outlet
            context={{
              draggedEvent,
              treatment,
              appointments,
              isLoading,
              activeProcedure,
              resetActiveProcedure,
              reloadAppointmentsList,
              activeAppointment,
              resources,
              isLoadingResources,
              updateTreatment,
              setResourcesSelected,
              isCancelledTreatment,
            }}
          />
        </Flex>
        <Flex css={{ width: '60%' }}>
          <Card extraClasses="wrapper full-width" isLoading={isLoading}>
            {!isLoading && (
              <AppointmentsCalendar
                treatment={treatment}
                activeProcedure={activeProcedure}
                activeAppointment={activeAppointment}
                customDate={customDate}
                setCustomDate={setCustomDate}
                setDraggedEvent={setDraggedEvent}
                isCancelledTreatment={isCancelledTreatment}
                selectedProviderSchedules={selectedProviderSchedules}
                eventsToShow={
                  unionBy(patientAppointments, otherAppointments, 'id') || []
                }
              />
            )}
          </Card>
        </Flex>
      </Flex>
    </PageTemplate>
  )
}

export default ScheduleAppointment
