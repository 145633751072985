import { FormProvider } from 'react-hook-form'

import type { ReactElement } from 'react'
import type { UseFormReturn, FieldValues } from 'react-hook-form'

import { StyledFormContent } from './form.styles'
import { FormControls } from './FormControls'

export type FormProps<T extends FieldValues> = {
  form: UseFormReturn<T>
  children?: ReactElement | Array<ReactElement | null>
  formActions: {
    cancel: () => void
    submit: () => void
  }
  formState: {
    isDisabled?: boolean
    isSubmitting: boolean
  }
  additionalActions?: React.ReactNode
  onFormChange?: () => void
}

export const Form = <T extends FieldValues>({
  form,
  children,
  onFormChange,
  formState,
  formActions,
  additionalActions,
}: FormProps<T>) => {
  return (
    <FormProvider {...form}>
      <form onChange={onFormChange} style={{ flex: 1 }}>
        <StyledFormContent>
          {children}
          <FormControls
            {...formState}
            {...formActions}
            additionalActions={additionalActions}
          />
        </StyledFormContent>
      </form>
    </FormProvider>
  )
}
